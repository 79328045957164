import {FC} from 'react'

type Props = {
  purchase_date?: string
}

const SalesLastLoginCell: FC<Props> = ({purchase_date}) => (
  <div className='badge badge-light fw-bolder'>{purchase_date}</div>
)

export {SalesLastLoginCell}
