import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Sales, SalesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const SALES_URL = `${API_URL}/sale`

const getSales = (query: string): Promise<SalesQueryResponse> => {
  return axios
    .get(`${SALES_URL}?${query}`)
    .then((d: AxiosResponse<SalesQueryResponse>) => d.data)
}

const getSalesById = (id: ID): Promise<Sales | undefined> => {
  return axios
    .get(`${SALES_URL}/view/${id}`)
    .then((response: AxiosResponse<Response<Sales>>) => response.data)
    .then((response: Response<Sales>) => response.data)
}

const createSales = (sale: Sales): Promise<Sales | undefined> => {
  return axios
    .post(`${SALES_URL}/create`, sale)
    .then((response: AxiosResponse<Response<Sales>>) => response.data)
    .then((response: Response<Sales>) => response.data)
}

const updateSales = (sale: Sales): Promise<Sales | undefined> => {
  return axios
    .post(`${SALES_URL}/update/${sale.sale_id}`, sale)
    .then((response: AxiosResponse<Response<Sales>>) => response.data)
    .then((response: Response<Sales>) => response.data)
}

const deleteSales = (saleId: ID): Promise<void> => {
  return axios.post(`${SALES_URL}/delete/${saleId}`).then(() => {})
}

const deleteSelectedSales = (saleIds: Array<ID>): Promise<void> => {
  const requests = saleIds.map((id) => axios.post(`${SALES_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getSales, deleteSales, deleteSelectedSales, getSalesById, createSales, updateSales}
