/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Sales} from '../../core/_models'

type Props = {
  sale: Sales
}

const SalesInfoCell: FC<Props> = ({sale}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {sale.cust_name}
      </a>
      <span>{sale.cust_mobile}</span>
    </div>
  </div>
)

export {SalesInfoCell}
