import {FC} from 'react'

type Props = {
  sale_status?: string
}

const SalesTwoStepsCell: FC<Props> = ({sale_status}) => (
  <> {sale_status=='1' && <div className='badge badge-light-success fw-bolder'>Completed</div>}
    {sale_status=='0' && <div className='badge badge-light-danger fw-bolder'>Pending</div>}
  </>
)

export {SalesTwoStepsCell}
