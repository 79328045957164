import {Column} from 'react-table'
import {SalesInfoCell} from './SalesInfoCell'
import {SalesLastLoginCell} from './SalesLastLoginCell'
import {SalesTwoStepsCell} from './SalesTwoStepsCell'
import {SalesActionsCell} from './SalesActionsCell'
import {SalesSelectionCell} from './SalesSelectionCell'
import {SalesCustomHeader} from './SalesCustomHeader'
import {SalesSelectionHeader} from './SalesSelectionHeader'
import {Sales} from '../../core/_models'

const salesColumns: ReadonlyArray<Column<Sales>> = [
  {
    Header: (props) => <SalesCustomHeader tableProps={props} title='Sl No' className='min-w-70px' />,
    id: 'selection',
    Cell: ({...props}) => <SalesSelectionCell num={props.row.index+1} />,
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='Customer Name' className='min-w-125px' />
    ),
    id: 'cust_name',
    Cell: ({...props}) => <SalesInfoCell sale={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='Product Name' className='min-w-125px' />
    ),
    accessor: 'prod_name',
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='Purchase Date' className='min-w-125px' />
    ),
    id: 'purchase_date',
    Cell: ({...props}) => (
      <SalesLastLoginCell purchase_date={props.data[props.row.index].purchase_date} />
    ),
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='Purchase Amount' className='min-w-125px' />
    ),
    accessor: 'purchase_amount',
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'sale_status',
    Cell: ({...props}) => <SalesTwoStepsCell sale_status={props.data[props.row.index].sale_status} />,
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SalesActionsCell id={props.data[props.row.index].sale_id} />,
  },
]

export {salesColumns}
