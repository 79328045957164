import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_metronic/partials/widgets'
import {useAuth} from '../../../app/modules/auth'
import {SalesTable} from '../sales/sales-list/table/SalesTable'
import React, {FC, useEffect, useState} from 'react'
import {getDashboardCounts} from './core/_requests'
import {Dashboard} from './core/_models'

type Props = {
  data?: Dashboard
}

const DashboardPage: FC<Props> = ({data}) => (
  <>
    <div className='row gy-5 g-xl-10'>
      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget13
          data={data}
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#F7D9E3'
          chartHeight='100px'
        />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget14
        data={data}
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#CBF0F4'
          chartHeight='100px'
        />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget15 data={data} className='card-xl-stretch mb-xl-10' backGroundColor='#CBD4F4' />
      </div>
      {/*end::Col*/}
    </div>
    {/*end::Row*/}

    {/* <TablesWidget9 className='mb-5 mb-xl-10' /> */}
    <SalesTable />
  </>
)

const DashboardWrapper = () => {
  const [dashboardData, setDashboardData] = useState<any>()
  const {currentUser} = useAuth()
  useEffect(() => {
    getDashboardCounts().then((response) => {
      setDashboardData(response!)
    })
  }, [])
  return (
    <EnableSidebar>
      <PageTitle description={currentUser?.user_type} breadcrumbs={[]}>
        Hello {currentUser?.name}
      </PageTitle>
      <DashboardPage data={dashboardData} />
    </EnableSidebar>
  )
}

export {DashboardWrapper}
