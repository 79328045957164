/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/apps/sales-management'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Sales'
        fontIcon='bi-layers'
      />
      {/* <AsideMenuItem
        to='/apps/customers-management'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Customers'
        fontIcon='bi-layers'
      /> */}
         <AsideMenuItem
        to='/apps/products-management'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Products'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
          <a onClick={logout} className='menu-link px-5'>
          <span className='menu-icon'>
          <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-5' svgClassName=' ' />
          </span>
          <span className='menu-title'>Sign Out</span> 
        </a>
      </div>
    </>
  )
}
