import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Dashboard, DashboardQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const BASE_URL = `${API_URL}/user/`



const getDashboardCounts = (): Promise<Dashboard | undefined> => {
  return axios
    .get(`${BASE_URL}dashboard`)
    .then((response: AxiosResponse<Response<Dashboard>>) => response.data)
    .then((response: Response<Dashboard>) => response.data)
}

export {getDashboardCounts}
