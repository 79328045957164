import {FC, useMemo} from 'react'
import {ID} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'

type Props = {
  num: number
}

const SalesSelectionCell: FC<Props> = ({num}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{num}</div>
    </div>
  )
}

export {SalesSelectionCell}
